<template>
  <v-card class="elevation-0">
    <v-card-text style="padding-left: 0; padding-right: 0;">
      <v-container
        grid-list-xl
        style="padding-top: 0"
      >
        <v-card
          flat
          tile
          v-if="isLoading"
          class="d-flex justify-center pb-1 pt-3"
        >
          <v-progress-circular
            :size="55"
            indeterminate
            color="primary"
          />
        </v-card>

        <div
          v-else
          class="ck ck-content ck-blurred"
          lang="pt-br"
          dir="ltr"
          role="textbox"
          v-html="conteudo"
        />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { parse } from 'node-html-parser'
import '@/components/fields/FieldEditor'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      isLoading: true,
      conteudo: ''
    }
  },

  mounted () {
    const { params } = this.$route

    if (!params?.id) {
      const content = localStorage.getItem('tmpContent')
      if (content) {
        return Object.assign(this, {
          isLoading: false,
          conteudo: parseHTML(content)
        })
      }
    }

    this.$store.dispatch('loadLearnPage', params)
  },

  computed: {
    ...mapState({
      row: ({ learnPages }) => learnPages.row
    })
  },

  watch: {
    row: {
      deep: true,
      handler (row) {
        if (!row?.id) return
        Object.assign(this, {
          isLoading: false,
          conteudo: parseHTML(row.conteudo)
        })
      }
    }
  }
}

const parseHTML = (str) => {
  if (!str) return str
  const root = parse(`<div>${str}</div>`).getElementsByTagName('div')[0]

  root.querySelectorAll('figure.media').forEach((figure) => {
    const dmcOembed = figure.querySelectorAll('oembed').find((oembed) => {
      const url = oembed.getAttribute('url')
      const isDmcVivo = url.includes('dmcvivo.udv.org.br/v1/audios/')
      return isDmcVivo
    })

    if (dmcOembed) {
      figure.replaceWith(parse(`<audio style="position: relative" src="${dmcOembed.getAttribute('url')}" controls preload></audio>`))
    }
  })

  return root.toString()
}

</script>
